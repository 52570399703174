import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import ImageSlider from "./elements/ImageSlider"

const StudioSlider = ({ className }) => {
  const { images } = useStaticQuery(graphql`
    query {
      images: contentfulImageSlider(
        contentful_id: { eq: "66IOK9IRyJ8nstprBd77zl" }
      ) {
        id
        images {
          ... on ContentfulAsset {
            id
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
      }
    }
  `)

  return (
    <>
      <div className={className}>
        <ImageSlider images={images.images} height={250} />
      </div>
      <hr style={{ margin: "5rem 0" }} />
    </>
  )
}

export default StudioSlider
