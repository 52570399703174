import React from "react"

import Page from "../templates"
import SEO from "../templates/seo"

import StudioSlider from "../components/StudioSlider"
import Workshops from "../components/Workshops"

const Kurse = () => (
  <Page>
    <SEO title="Kunstkurse" />
    <h1 className="text-primary">Kunstkurse</h1>
    <p>Raus aus dem Alltag - rein ins Atelier und selber gestalten!</p>
    <p>
      Werde selbst aktiv und erlerne das Malen mit unterschiedlichen Farben und
      Techniken auf Papier, Pappe, Hartfaser, Holz, Gips, Leinwand... In einer
      Kleingruppe von maximal vier Personen gestaltest du Schritt für Schritt
      dein eigenes, ganz individuelles Kunstwerk.
    </p>

    <StudioSlider />
    <Workshops />
  </Page>
)

export default Kurse
