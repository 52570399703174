import React from "react"
import SimpleReactLightbox from "simple-react-lightbox"
import { SRLWrapper } from "simple-react-lightbox"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ImageSlider = props => {
  const options = {
    buttons: {
      iconColor: "#6297a7",
      showAutoplayButton: false,
      showDownloadButton: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
    caption: {
      showCaption: false,
    },
  }

  return (
    <SimpleReactLightbox>
      <SRLWrapper options={options}>
        <div
          className="scrolling-wrapper-flexbox"
          style={{ marginLeft: "-15px", marginRight: "-15px" }}
        >
          {props.images.map(image => {
            const { id } = image
            const { width, height } = getImage(image)
            return (
              <div key={id} className="ml-3" style={{ cursor: "pointer" }}>
                <GatsbyImage
                  style={{
                    height: `${props.height}px`,
                    width: (width / height) * props.height + "px",
                  }}
                  loading="auto"
                  image={getImage(image)}
                  alt=""
                />
              </div>
            )
          })}
        </div>
      </SRLWrapper>
    </SimpleReactLightbox>
  )
}

export default ImageSlider
