import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"

const Workshops = ({ className }) => {
  const { workshops } = useStaticQuery(graphql`
    query {
      workshops: allContentfulWorkshopDescription(
        sort: { order: ASC, fields: order }
      ) {
        nodes {
          id
          title
          price
          description {
            description
          }
          image {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
      }
    }
  `)

  return (
    <div className={className}>
      {workshops.nodes.map(workshop => (
        <div className="mt-5">
          <Card>
            <Row className="no-gutters">
              <Col md={4} lg={2} className="d-none d-md-block">
                <GatsbyImage
                  className="card-img"
                  image={getImage(workshop.image)}
                />
              </Col>
              <Col xs={12} md={8} lg={10}>
                <Card.Body>
                  <Card.Title>{workshop.title}</Card.Title>
                  <Card.Text>{workshop.description.description}</Card.Text>
                  <Card.Text>{workshop.price}</Card.Text>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </div>
      ))}

      <div className="mt-5">
        <p>
          Alle Kunstkurse werden von mir individuell zusammengestellt, weshalb
          es Angebote sowohl für Anfänger als auch Fortgeschrittene gibt. Genaue
          Kurstermine spreche ich gerne mit Dir persönlich ab, hinterlass mir
          einfach eine Nachricht! Alle Kunstkurse finden in meinem Atelier im
          Galerie-Hotel in Paderborn statt.
        </p>
      </div>
    </div>
  )
}

export default Workshops
